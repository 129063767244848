<template>
  <div>
    <div class="col float-right">
      <q-btn-group v-show="editable&&completeFlag" outline>
        <c-btn 
          :isSubmit="isComplete"
          :url="completeUrl"
          :param="assessPlan"
          mappingType="PUT"
          label="평가완료" 
          icon="check"
          @beforeAction="completeAction"
          @btnCallback="completeActionCallback" />
      </q-btn-group>
    </div>
    <c-tab
      ref="kpsrActionTab"
      type="vertical"
      :tabItems="tabItems"
      :height.sync="height"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :key="tab.key"
          :process.sync="tab.process"
          :param.sync="param"
          :planUpdateBtnData="param.planUpdateBtnData"
          @assessComplete="assessComplete"
          @research="research"
          @closePopup="closePopup"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'kpsr-action',
  props: {
    param: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
        searchRiskReduce: {
          flag: '',
          isSearch: false,
        },
        searchScenario: {
          flag: '',
          isSearch: false,
        },
        planUpdateBtnData: {
          title: '계획',
          flag: false,
          research: '',
          planResearch: '',
        },
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      tab: '',
      tabItems: [],
      editable: true,
      isComplete: false,
      listUrl: '',
      completeUrl: '',
    };
  },
  computed: {
    completeFlag() {
      return this.tabItems 
        && this.tabItems.length > 0
        && this.$_.findIndex(this.tabItems, (item) => {
              return item.process.ramProcessAssessStepCd !== 'RPA0000005'
            }) === -1 
        && this.param.ramStepCd === 'RRS0000010'
    },
    assessPlan() {
      return {
        ramRiskAssessmentPlanId: this.param.ramRiskAssessmentPlanId,
        ramStepCd: this.param.ramStepCd,
        chgUserId: this.$store.getters.user.userId,
      }
    }
  },
  watch: {
    'param.searchScenario.flag'() {
      this.getList();
    },
    'param.planUpdateBtnData.research'() {
      this.getList();
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.kpsr.targetProcess.url;
      this.completeUrl = transactionConfig.ram.assessPlan.complete.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.$format(this.listUrl, this.param.ramRiskAssessmentPlanId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.tabItems = [];
        this.$_.forEach(_result.data, item => {
          if (item && item.draws) {
            this.$_.forEach(item.draws, draw => {
              this.$set(draw, 'name', draw.psiDiagramId);
              this.$set(draw, 'icon', 'architecture');
              this.$set(draw, 'label', draw.diagramTitle + ' (' + draw.diagramNo + ')');
              this.$set(draw, 'componentKey', uid());
              this.$set(draw, 'component', () => import(`${'./kpsrScenario.vue'}`));
              this.$set(draw, 'draw', draw);
            })
          }
          this.tabItems.push({
            key: uid(),
            name: item.processCd, icon: 'work_outline', label: item.processName, process: item, component: () => import(`${'./kpsrActionDetail.vue'}`)
          })
        })
        this.tab = _result.data[0].processCd
        this.$refs['kpsrActionTab'].setValue(this.tab)
      },);
    },
    assessComplete(stepCd) {
      this.getList();
      // stepper 이동
      if (stepCd === 'RRS0000015') {
        this.$emit('emitStep', {
          name: 'stepBystep',
          param: stepCd
        })
      }
    },
    research(place) {
      this.$emit('emitStep', {
        name: 'research',
        param: {
          place: place,
        }
      })
    },
    completeAction() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '완료하시겠습니까?',
        
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.assessPlan.chgUserId = this.$store.getters.user.userId
          this.assessPlan.ramStepCd = 'RRS0000015'

          this.isComplete = !this.isComplete
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    completeActionCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      // 계획 상세 조회
      this.getList();
      // stepper 이동
      this.$emit('emitStep', {
        name: 'stepBystep',
        param: this.assessPlan.ramStepCd
      })
    },
    closePopup() {
      this.$emit('emitStep', {
        name: 'closePopup'
      })
    }
  }
};
</script>
